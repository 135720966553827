<template lang="pug">
.fc__container
</template>

<script>
import { mapState } from 'vuex';

import { convertDateFormat } from '@/assets/scripts/utilities';

export default {
  name: 'Freshchat',
  data() {
    return {
      widgetReady: false,
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    ...mapState('userData', [
      'userLevel',
      'name',
      'userId',
      'userEmail',
      'isNotified',
      'isSubscribed',
      'activeProjects',
      'exportedProjectsAsVideos',
      'exportedProjectsAsImages',
      'currentPlan',
      'previousPlan',
      'customerId',
      'subscriptionId',
      'ltdTransactionId',
      'latestProjects',
      'teamSeats',
      'hasSetupBrand',
      'createdAt',
    ]),
  },
  watch: {
    // eslint-disable-next-line
    '$freshchat.ready': function (isReady) {
      if (isReady) {
        this.updateFreshchatUser();
      }
    },
  },
  methods: {
    updateFreshchatUser() {
      this.$freshchat.setExternalId(this.userId);
      this.$freshchat.showWidget();

      // Set data to update Freshchat user
      const userMeta = {
        'User ID': this.userId,
        'User Type': this.userLevel,
        'Registration Date': convertDateFormat(this.created_at),
        'Browser Language': window.navigator.userLanguage || window.navigator.language,
        'Is Subscribed': this.isSubscribed,
        'Is Notified': this.isNotified,
        'Active Projects': this.activeProjects,
        'Exported Projects (as Video)': this.exportedProjectsAsVideos,
        'Exported Projects (as Image)': this.exportedProjectsAsImages,
        'Current Plan': this.currentPlan,
        'No. of Team Members': this.teamSeats,
        'Has Brand Setup': this.hasSetupBrand,
      };

      if (this.previousPlan) {
        userMeta['Last Plan'] = this.previousPlan;
      }

      if (this.customerId) {
        userMeta['Braintree Customer ID'] = this.customerId;
      }

      if (this.subscriptionId) {
        userMeta['Subscription ID'] = this.subscriptionId;
      }

      if (this.ltdTransactionId) {
        userMeta['LTD Transaction ID'] = this.ltdTransactionId;
      }

      if (this.latestProjects[0]) {
        // eslint-disable-next-line
        userMeta['Project ID (0)'] = this.latestProjects[0];
      }

      if (this.latestProjects[1]) {
        // eslint-disable-next-line
        userMeta['Project ID (-1)'] = this.latestProjects[1];
      }

      if (this.latestProjects[2]) {
        // eslint-disable-next-line
        userMeta['Project ID (-2)'] = this.latestProjects[2];
      }

      const userProperties = {
        firstName: this.name,
        email: this.userEmail,
        meta: userMeta,
      };

      window.fcWidget.user.get().then(
        () => {
          // Update user
          window.fcWidget.user.update(userProperties).then(
            (response) => {
              console.log('FC User Updated', response);
            },
            (error) => {
              console.log('FC User Not Updated', error);
            },
          );
        },
        (error) => {
          // User not found
          if (error.status === 401) {
            // Create user
            window.fcWidget.user.create(userProperties).then(
              (response) => {
                console.log('FC User Created', response);
              },
              (createError) => {
                console.log('FC User Not Created', createError);
              },
            );
          }
        },
      );
    },
  },
  mounted() {
    this.$freshchat.hideWidget();
  },
};
</script>

<style lang="scss">
#fc_frame {
  bottom: -10px !important;
  right: -5px !important;

  &:not(.fc-open) {
    transform: scale(0.55);
  }
}

</style>
